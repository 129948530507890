// 此处动态插入文件，保证线上文件的统一
import { Html } from '@/common/js/lib/SL-es';
import 'promise-polyfill/src/polyfill';

const loadJs = url => {
  return new Promise((resolve, reject) => {
    try {
      Html.getScript(url, resolve, 'null', reject);
    } catch (error) {
      reject(error);
    }
  });
};
const loadNewsJs = () => {
  let prefix = 'https://mini.eastday.com/dfzxh5/home/js/';
  if (DEVELOPMENT) {
    prefix = './js/';
  }
  return loadJs(`${prefix}toutiao_d.min.js?${new Date().getTime()}`);
};

try {
  // eslint-disable-next-line no-undef
  _hmt.push(['_trackEvent', 'toutiaohome', 'activelog', 'enter_00']);
} catch (e) {
  console.error(e);
}

const loadCommonConfigJs = p => {
  return Promise.all([loadNewsJs(p)]);
};
loadCommonConfigJs()
  .then(() => {
    console.log('success');
  })
  .catch(e => {
    console.log(e);
  });
